.wrapper {
    background-color: $white;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    overflow: hidden;

    @include breakpoint(medium down) {
        margin-top: 0;
    }
}

.bg-secondary {
    background-color: $secondary-color;
    color: $white;
    a {
        color: $white;
    }
}

.bg-primary {
    background-color: $primary-color;
    color: $white;
    a {
        color: $white;
    }
}

section {
    .grid-container {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

.divider {
    background-color: $primary-color;
    color: $white;
    padding-top: rem-calc(22);
    padding-bottom: rem-calc(20);
}

input,
textarea {
    transition: all 0.35s ease-in-out !important;
}

textarea {
    height: 110px;
    resize: vertical;
}
