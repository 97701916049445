.mobil-menu-wrap {
    background-color: $primary-color;
    height: 70px;
}

/* Main menu positionning */
.main-nav-mobil {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: all 0.375s;
}

.main-nav-mobil.is-open {
    opacity: 1;
    z-index: 100;
    visibility: visible;
}

/* Yellow band effect */
.main-nav-mobil::before {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $primary-color;
    transform-origin: 0 0;
    transition: all 0.275s 0.1s;
}

.main-nav-mobil.is-open::before {
}

/* Skewing effect on menu links */
.main-nav-mobil ul {
    display: inline-flex;
    flex-direction: column;
    height: 93%; /* Should be 100%, but we have a notice message :D */
    align-items: center;
    justify-content: center;
}

.main-nav-mobil li {
    display: block;
    margin: 0.5rem 0;
    text-align: center;
}

/* Apparition effect on links */
.main-nav-mobil a {
    opacity: 0;
    transform: translateY(-10px);
}

.main-nav-mobil.is-open a {
    opacity: 1;
    transform: translateY(0);
}
.main-nav-mobil li:nth-child(1) a {
    transition: all 275ms 175ms;
}
.main-nav-mobil li:nth-child(2) a {
    transition: all 275ms 225ms;
}
.main-nav-mobil li:nth-child(3) a {
    transition: all 275ms 275ms;
}
.main-nav-mobil li:nth-child(4) a {
    transition: all 275ms 325ms;
}
.main-nav-mobil li:nth-child(5) a {
    transition: all 275ms 375ms;
}
.main-nav-mobil li:nth-child(6) a {
    transition: all 275ms 425ms;
}

/* Decoration */
.main-nav-mobil ul,
.main-nav-mobil li {
    list-style: none;
    padding: 0;
}
.main-nav-mobil a {
    display: block;
    padding: 12px 0;
    color: $white;
    font-size: 1.4em;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
}

/* Burger Style: @see: https://codepen.io/CreativeJuiz/full/oMZNXy */
.open-main-nav-mobil {
    position: absolute;
    top: 15px;
    padding-top: 20px;
    right: 15px;
    z-index: 1000;
    background: none;
    border: 0;
    cursor: pointer;
}
.open-main-nav-mobil:focus {
    outline: none;
}
.burger {
    position: relative;
    display: block;
    width: 28px;
    height: 4px;
    margin: 0 auto;
    background: $white;
    transform: skew(5deg);
    transition: all 0.275s;
}

.burger:after,
.burger:before {
    content: "";
    display: block;
    height: 100%;
    background: $white;
    transition: all 0.275s;
}

.burger:after {
    transform: translateY(-12px) translateX(-2px) skew(-20deg);
}

.burger:before {
    transform: translateY(-16px) skew(-10deg);
}

/* Toggle State part */
.is-open .burger {
    transform: skew(5deg) translateY(-8px) rotate(-45deg);
}

.is-open .burger:before {
    transform: translateY(0px) skew(-10deg) rotate(75deg);
}

.is-open .burger:after {
    transform: translateY(-12px) translateX(10px) skew(-20deg);
    opacity: 0;
}

/* MENU Text part */

.burger-text {
    display: block;
    font-size: 0.675rem;
    letter-spacing: 0.05em;
    margin-top: 0.5em;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    color: $white;
}
