table {
    tbody td:nth-child(odd) {
        background-color: $primary-color;
        color: $white;
        text-align: right;
        text-transform: uppercase;
        font-weight: 400;
    }

    &.unstriped tbody,
    &.unstriped tbody tr {
        background-color: transparent;
    }
}

.team {
    margin-top: -80px;
    position: relative;

    @include breakpoint(medium down) {
        margin-top: 0;
    }

    .grid-container {
        font-weight: 700;
        font-size: rem-calc(20);
        padding: 1.5rem 0 0.5rem;

        @include breakpoint(medium down) {
            font-size: rem-calc(16);
            padding: 1rem 0 0.2rem;
        }
    }
}

.accordion-item {
    border-bottom: 10px solid $primary-color;
}
.accordion-item.is-active {
    .accordion-title {
        background-color: darken($secondary-color, 10%);

        &:focus,
        &:hover,
        &:active {
            color: $white;
        }
    }
}
.accordion-title {
    &::before {
        font-size: rem-calc(40);
        top: 25%;
    }

    &:focus,
    &:hover,
    &:active {
        color: $white;
    }
}

#sprechzeiten {
    background: transparent url("../img/bg-clock.png") no-repeat bottom right;
    background-size: 25% auto;
}
