.logo-text {
    font-size: rem-calc(30);
    text-transform: uppercase;
    font-weight: 400;
    padding-top: rem-calc(80);
    padding-bottom: rem-calc(40);

    @include breakpoint(medium down) {
        font-size: rem-calc(18);
        padding-top: rem-calc(40);
        padding-bottom: rem-calc(20);
    }
}

.header-image {
    margin-bottom: -104px;
    @include breakpoint(medium down) {
        margin-bottom: 0;
    }
}

.nav-wrap {
    &.is-stuck {
        background-color: $secondary-color;
        width: 100%;

        .main-nav {
            padding-top: rem-calc(10);
            padding-bottom: rem-calc(0);
        }
    }
}
.main-nav {
    padding-top: rem-calc(35);
    padding-bottom: rem-calc(17);
    // transition: all 0.2s linear;

    li {
        position: relative;

        &::after {
            content: "|";
            color: #ffffff;
            font-size: 20px;
            line-height: 38px;
            display: inline-block;
            vertical-align: bottom;
            font-style: normal;
            position: absolute;
            top: 0;
            left: 0;
        }
        &:first-child {
            &::after {
                display: none;
            }
        }

        a {
            color: $white;
            text-transform: uppercase;
            font-size: rem-calc(18);
            padding: 0;
            padding-bottom: 0.7rem;
            margin: 0.7rem 1rem;
            margin-left: 1.7rem;
            border-bottom: 1px solid transparent;

            &.is-active,
            &:hover,
            &:active {
                border-bottom: 1px solid $white;
            }
        }
    }
}
